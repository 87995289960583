import { useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import {Row,Col,Tab,Container} from 'react-bootstrap'
import HomeDashboard from '../components/dashboard/HomeDashboard'
import SettingsDashboard from '../components/dashboard/SettingsDashboard'
import CreateDashboard from '../components/dashboard/CreateDashboard'
import PlanDashboard from '../components/dashboard/PlanDashboard'
import PricingDashboard from '../components/dashboard/PricingDashboard'
import RedeemDashboard from '../components/dashboard/RedeemDashboard'
import NavbarDashboard from '../components/dashboard/NavbarDashboard'
import UpgradeDashboard from '../components/dashboard/UpgradeDashboard'
import logoImage from '../assets/uuki-logo.png'
import '../styles/dashboard.css'
import { useMediaQuery } from 'react-responsive'


function Dashboard() {
    const userState = useSelector(state => state.user)
    const [userCommunities, setUserCommunities] = useState([])
    const [user, setUser] = useState({name:'',email:'',profile_pic:'',enable_addon:0,plan:0,userCodes:0,recur_plan:0,limits:{},codes:0,asCodes:[],maxCodes:0})
    const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
    useEffect(() => {

        axios.get("/v2/get_dashboard_details").
        then((response)=>{
            setUserCommunities(response.data.communities)
            setUser({name:response.data.user_details.name,
                email:response.data.user_details.email,
                profile_pic:response.data.user_details.profile_pic,
                enable_addon:response.data.user_details.enable_addon,
                plan:response.data.user_details.plan,
                userCodes:response.data.user_details.codes,
                recur_plan:response.data.user_details.recur_plan,
                limits:response.data.user_limits,
                codes:response.data.user_codes,
                asCodes:response.data.user_as_codes,
                maxCodes:response.data.max_codes.maxCodes})
          
        }).catch((err)=>{
            console.error(err)
        })
        document.getElementById("community-favicon").href = logoImage;
    },[])
    const [key, setKey] = useState('home');
    document.title = "Dashboard"
    return (
        <Tab.Container id="dashboard-nav"  activeKey={key} onSelect={(k) => setKey(k)}>
                <Row className="dashboard-wrapper g-0">
                    <NavbarDashboard user={user}  setKey={setKey}/>
                    <Col className="mt-5 py-4 ps-0 overflow-hidden" md={isBigScreen?{ span: 7, offset: 4 }: { span: 7, offset: 3 }}>
                        <Tab.Content className="dashboard-content center-container-dashboard uuki-box p-3 px-4 my-md-2">
                            <Tab.Pane eventKey="home">
                                <HomeDashboard  communities={userCommunities}  setKey={setKey}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="settings">
                                <SettingsDashboard  user={user}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="plan">
                                <PlanDashboard  user={user} setKey={setKey}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pricing">
                                <PricingDashboard  user={user}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="redeem">
                                <RedeemDashboard user={user} setKey={setKey}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="create">
                                <CreateDashboard setKey={setKey}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="upgrade">
                                <UpgradeDashboard />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container> 
    )
}

export default Dashboard;
