import React, { useState, useEffect } from 'react';

function Embed() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [communityId, setcommunityId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getAnswer = async () => {
    const response = await fetch('https://your-backend-api.com/answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id:communityId, question:question}),
    });

    const data = await response.json();
    setAnswer(data.answer);
  };

   useEffect(() => {
    // Access the iframe element from the parent window
    const iframe = window.parent.document.getElementById('myIframe');

    // Check if the iframe exists
    if (iframe) {
      // Accessing data attribute using dataset
      const cid = iframe.dataset.community_id;
      setcommunityId(cid);

    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    getAnswer();
  };

return (
<div className="container d-flex flex-column align-items-center justify-content-center">
      <form onSubmit={handleSubmit} className="text-center">
        <div className="form-group">
          <input
            type="text"
            className="form-control col-md-8"
            id="question"
            value={question}
            placeholder="Ask a question"
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">Get Answer</button>
      </form>
      {isLoading && (
        <div className="progress mt-4" style={{ width: '50%', textAlign: 'center' }}>
          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: '100%' }}>
            Loading...
          </div>
        </div>
      )}
      {!isLoading && answer && (
        <div className="mt-4 text-center">
          <h2>Answer:</h2>
          <p>{answer}</p>
        </div>
      )}
      {/* Footer */}
      <footer className="mt-5 text-center">
        <p>Powered by <a href="https://uuki.live" target="_blank" rel="noopener noreferrer">UUKI</a></p>
      </footer>
    </div>
  );
}

export default Embed;
