import {FormCheck, Stack,Button,Row,Col} from 'react-bootstrap'
import {ReactComponent as IncludedIcon} from '../../assets/included.svg'

import {useState} from 'react'
function UpgradeDashboard() {
    const [yearly, setYearly] = useState(false)
    const goToLink = (plan) => {
        if(yearly) {
            if(plan == 1) {
                window.location.href = "https://payments.pabbly.com/subscribe/61d77e3f75de1b6dfa0f6e8a/starter_yearly";			    
            } else if(plan == 2) {
                window.location.href = "https://payments.pabbly.com/subscribe/61d77e5e75de1b6dfa0f6e94/pro_yearly";
            } else {
                window.location.href = "https://payments.pabbly.com/subscribe/61d77e9075de1b6dfa0f6e9d/advance_yearly";
            }
        } else {
            if(plan == 1) {
                window.location.href = "https://payments.pabbly.com/subscribe/61d77d7875de1b6dfa0f6e7e/starter";
            } else if(plan == 2) {
                window.location.href = "https://payments.pabbly.com/subscribe/61d77dfe75de1b6dfa0f6e87/pro";
            } else {
                window.location.href = "https://payments.pabbly.com/subscribe/61d77e1775de1b6dfa0f6e89/advance";
            }
        }
    }
    return (
        <div className="">
            <h4 className="mb-3">Upgrade</h4> 
            
            <FormCheck 
                type="switch"
                id="custom-switch"
                label="Monthly/Yearly"
                className="mb-3"
                onChange={() => setYearly(!yearly)}
            />

            <Row>
                <Col className="py-4 px-3 mx-1 border rounded" onClick={() => goToLink(1)}>
                    <Stack gap={3}>
                        <Stack gap={3} direction="horizontal"><h5>Starter</h5></Stack>
                        <Stack gap={3} direction="horizontal"><span id="starter_price" className="plan-price">${yearly?15:19}/mo</span><span className="plan-type"></span></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>500 members</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>1 Community</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>1 Mod/Admin</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>5 GB storage</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>1 Spaces</b></Stack>
                        <div className="content-border"></div>
                        <Button className="uuki-btn uuki-dashboard-btn">Select this plan</Button>
                    </Stack>
                  
                    
                </Col>
                <Col className=" py-4 px-3 mx-1 border rounded" onClick={() => goToLink(2)}>
                    <Stack gap={3}>
                        <Stack gap={3} direction="horizontal"><h5>Pro</h5></Stack>
                        <Stack gap={3} direction="horizontal"><span id="pro_price" className="plan-price">${yearly?65:79}/mo</span><span className="plan-type"></span></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>1000 members</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>1 Community</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>3 Mod/Admin</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Unlimited storage</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>3 Spaces</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Remove Branding</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>CNAME</b></Stack>
                        <div className="content-border"></div>
                        <Button className="uuki-btn uuki-dashboard-btn">Select this plan</Button>
                    </Stack>
                   
                    
                </Col>
                <Col className=" py-4 px-3 mx-1 border rounded" onClick={() => goToLink(3)}>
                    <Stack gap={3}>
                        <Stack gap={3} direction="horizontal"><h5>Advance</h5></Stack>
                        <Stack gap={3} direction="horizontal"><span id="advance_price" className="plan-price">${yearly?205:249}/mo</span><span className="plan-type"></span></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>10000 members</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>3 Community</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>5 Mod/Admin</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Unlimited storage</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>5 Spaces</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Remove Branding</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>CNAME</b></Stack>
                        <div className="content-border"></div>
                        <Button className="uuki-btn uuki-dashboard-btn">Select this plan</Button>
                    </Stack> 
                </Col>
            </Row>
        </div>
    )
}

export default UpgradeDashboard
