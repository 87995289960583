import {
	Form,
	InputGroup,
	Stack,
	Button,
	Popover,
	OverlayTrigger,
	FloatingLabel,
	DropdownButton,
	Dropdown,
	Modal,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from 'react-bootstrap';
import Picker from 'emoji-picker-react';
import { useState, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function SpaceSettings({ setKey, settings, setSpace }) {
	const [chosenEmoji, setChosenEmoji] = useState(null);
	const [emojiTrigger, setEmojiTrigger] = useState(null);
	const [spaceGroupName, setSpaceGroupName] = useState('');
	const [createWithAI, setCreateWithAI] = useState(false);
    const enableBeta = useSelector((state) => state.userReducer.enableBeta);
	const [showDelete, setShowDelete] = useState({
		id: 0,
		show: false,
		isGroup: false,
	});
	const [showGroupName, setShowGroupName] = useState(false);
	const [showSpace, setShowSpace] = useState({
		id: 0,
		show: false,
		name: '',
		content: 0,
		privacy: 1,
		layout: 0,
        embed_script: '',		
	});
	const [settingsSet, setSettingsSet] = useState(false);
	const currentId = useSelector((state) => state.communityReducer.communityId);
    const default_load_topic = 	useSelector((state) => state.communityReducer.default_load_topic);	
	const currentSlug = useSelector((state) => state.communityReducer.communitySlug);
	const [embed_script, setEmbedScript] = useState('');	

	
	const navigate = useNavigate()
	const onEmojiClick = (event, emojiObject) => {
		setChosenEmoji(emojiObject);
		let getInput = document.querySelector(
			'input[name="' + emojiTrigger + '"][data-isGroup="0"]'
		);
		if(emojiTrigger==='new-space'){
			 getInput = document.querySelector(
				'input[name="' + emojiTrigger + '"]'
			);
		}
		getInput.value = emojiObject.emoji + ' ' + getInput.value;
		
		document.body.click()
		changeName(getInput.name, getInput.value, false);
	};
	const emojiPopover = (
		<Popover style={{ maxWidth: null }}>
			<Picker onEmojiClick={onEmojiClick} />
		</Popover>
	);
	useEffect(() => {
		Object.entries(settings).length > 0 && setSettingsSet((prev) => true);
	}, [settings]);
	const changeName = useDebouncedCallback((id, value, isGroup) => {
		axios
			.post('/v2/change_space_name', { id: id, value: value, isGroup: isGroup })
			.then((res) => {
				toast.success('Name Updated');
			})
			.catch(function (error) {
				toast.error('Update Failed');
			});
	}, 1000);
	const addGroup = () => {
		axios
			.post('/v2/add_space_group', { id: currentId, value: spaceGroupName })
			.then((res) => {
				toast.success('Space Group Added');
                window.location.reload()
			})
			.catch(function (error) {
				toast.error('Space Group Not Added');
			});
	};
	const addSpace = () => {
		axios
			.post('/v2/add_space', { value: showSpace, commId: currentId,createWithAI:createWithAI})
			.then((res) => {
				toast.success('Space Added');
				setShowSpace(false)
				// window.location.reload(false)
				navigate('/c/'+currentSlug+"/"+res.data)
			})
			.catch(function (error) {
				toast.error('Space Not Added');
			});
	};
	const deleteSpace = () => {
		axios
			.post('/v2/delete_space', { value: showDelete })
			.then((res) => {
				document.body.click();
				toast.success('Space/Group Deleted');
				window.location.reload(false);

			})
			.catch(function (error) {
				document.body.click();
				toast.error('Space/Group Not Deleted');
			});
	};
	const changeDefaultLoadTopic = (value) => {
        const topic_id = value[value.selectedIndex].value;		
        console.log("Change topic", topic_id, currentId);
        axios
        .post("/v2/change_default_load_topic", {
          commId: currentId,
          topicId: topic_id,
        })
        .then((res) => {
          toast.success("Updated");
        })
        .catch(function (error) {
          toast.error("Error updating");
        });		
	}
	return (
		<>		
			<Stack key={settings.length} gap={4}>
			<Form.Group controlId="defaultLoadSpace">
                <Form.Label>Choose default space to load on entering community</Form.Label>
                <Form.Select onChange={(e) => changeDefaultLoadTopic(e.target)}>
                    <>
					    <option
                            selected={default_load_topic == 0}						  
                            value={0}
                        >
                            {"Home"}
                        </option>
                        {settings.spaces != null
                            ?  settings.spaces.map((space) => (
                                <option
                                    selected={space.id == default_load_topic}						  
                                    value={space.id}
                                >
                                    {space.name}
                                </option>
                              ))
                              : ""}
                    </>
                </Form.Select>			
            </Form.Group>
			{settingsSet &&			
					settings.space_groups.map((spaceGroup) => (
						<Stack key={spaceGroup.id} gap={4}>
							<Stack direction='horizontal'>
								<FloatingLabel
									controlId='spaceGroup'
									label='Edit Space Group Name'>
									<Form.Control
										className='space-group-field'
										data-isGroup='1'
										name={spaceGroup.id}
										defaultValue={spaceGroup.name}
										onChange={(event) =>
											changeName(
												event.target.name,
												event.target.value,
												event.target.getAttribute('data-isGroup')
											)
										}
									/>
								</FloatingLabel>
								<Dropdown>
									<Dropdown.Toggle className='bg-transparent border-0 text-dark'>
										<i className='fa-solid fa-ellipsis'></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() =>
												setShowDelete({
													id: spaceGroup.id,
													show: true,
													isGroup: true,
												})
											}>
											Delete Group
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Stack>
							{settings.spaces.map(
								(space) =>
									space.group_id == spaceGroup.id && (
										<Stack key={space.id} direction='horizontal'>
											<InputGroup className='ms-4 w-auto flex-nowrap settings-input-group'>
												<FloatingLabel
													controlId='spaceGroup'
													label='Edit Space Name'>
													<Form.Control
														className='space-field'
														data-isGroup='0'
														name={space.id}
														defaultValue={space.name}
														onChange={(event) =>
															changeName(
																event.target.name,
																event.target.value,
																event.target.getAttribute('data-isGroup')
															)
														}
													/>
												</FloatingLabel>
												<OverlayTrigger
													trigger='click'
													placement='bottom'
													
													
													rootClose
													overlay={emojiPopover}>
													<Button
														variant='secondary'
														onClick={(event) => setEmojiTrigger(space.id)}>
														<i className='fa-regular fa-face-smile-beam'></i>
													</Button>
												</OverlayTrigger>
											</InputGroup>
											<Dropdown>
												<Dropdown.Toggle className='bg-transparent border-0 text-dark'>
													<i className='fa-solid fa-ellipsis'></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															setKey('spaceEdit');
															setSpace(space);
														}}>
														Edit Space
													</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item
														onClick={() =>
															setShowDelete({
																id: space.id,
																show: true,
																isGroup: false,
															})
														}>
														Delete Space
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</Stack>
									)
							)}
							<Button
								className='uuki-btn uuki-community-btn ms-4 align-self-start'
								onClick={() => {
									setShowSpace({
										id: spaceGroup.id,
										name: '',
										privacy: 1,
										layout: 0,
										content: 0,
										show: true,
									});
								}}>
								Add a Space
							</Button>
						</Stack>
					))}

				<Button
					className='uuki-btn uuki-community-btn align-self-start'
					onClick={() => setShowGroupName(true)}>
					Add a Space Group
				</Button>
			</Stack>

			<Modal
				show={showDelete.show}
				onHide={() => setShowDelete(false)}
				centered>
				<Modal.Header closeVariant='white' closeButton>
					<Modal.Title>Are you Sure?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Stack gap={4}>
						<span>
							Deleting will remove all of the{' '}
							{showDelete.isGroup ? "space group's" : "space's"} posts and
							comment content.
						</span>
					</Stack>
				</Modal.Body>
				<Modal.Footer className='border-0 justify-content-center'>
					<Button className='uuki-delete-btn' onClick={deleteSpace}>
						Delete {showDelete.isGroup ? 'Space Group' : 'Space'}
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showGroupName}
				onHide={() => setShowGroupName(false)}
				centered>
				<Modal.Header closeVariant='white' closeButton>
					<Modal.Title>Add New Space Group</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Stack gap={4}>
						<Form.Group controlId='spaceGrpName'>
							<Form.Label>Enter Space Group Name</Form.Label>
							<Form.Control
								onChange={(event) =>
									setSpaceGroupName((prev) => event.target.value)
								}
							/>
						</Form.Group>
					</Stack>
				</Modal.Body>
				<Modal.Footer className='border-0 justify-content-center'>
					<Button className='uuki-btn uuki-community-btn' onClick={addGroup}>
						Save Space Group
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showSpace.show} onHide={() => setShowSpace(false)} centered>
				<Modal.Header closeVariant='white' closeButton>
					<Modal.Title>Add New Space</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Stack gap={4}>
						<Form.Group controlId='spaceGrpName'>
							<Form.Label>Enter Space Name</Form.Label>
							<InputGroup className='settings-input-group'>
								<Form.Control
									className='space-field'
									name='new-space'
									
									onChange={(event) =>
										setShowSpace({ ...showSpace, name: event.target.value })
									}
								/>
								<OverlayTrigger
									trigger='click'
									
									placement='bottom'
									
									rootClose
									overlay={emojiPopover}>
									<Button
										variant='secondary'
										onClick={(event) => setEmojiTrigger('new-space')}>
										<i className='fa-regular fa-face-smile-beam'></i>
									</Button>
								</OverlayTrigger>
							</InputGroup>
						</Form.Group>

						<Row className='align-self-start'>
							<Form.Label>Set Content Type</Form.Label>
							<ToggleButtonGroup
								type='radio'
								name='editContentOptions'
								defaultValue={0}
								onChange={(val) =>
									setShowSpace({ ...showSpace, content: val })
								}>
								<ToggleButton
									style={{
										borderTopLeftRadius: '1.8rem',
										borderBottomLeftRadius: '1.8rem',
									}}
									className='p-3'
									variant='outline-dark'
									id='content-1'
									value={0}>
									<i className='fa-solid fa-square me-2'></i>Posts
								</ToggleButton>
								<ToggleButton
									
									className='p-3'
									variant='outline-dark'
									id='content-2'
									value={1}>
									<i className='fa-solid fa-calendar-week me-2'></i>Events
								</ToggleButton>
								<ToggleButton
									className="p-3 "
									variant='outline-dark'
									id='content-3'
									value={2}>
									<i className='fa-solid fa-graduation-cap me-2'></i>Courses
								</ToggleButton>
                                <ToggleButton
									style={{
										borderTopRightRadius: '1.8rem',
										borderBottomRightRadius: '1.8rem',
									}}
									className="p-3 "
									variant='outline-dark'
									id='content-4'
									value={3}>
									<i className='fa-solid fa-graduation-cap me-2'></i>Embed
								</ToggleButton>								
							</ToggleButtonGroup>
						</Row>

						<Row className='align-self-start'>
						<Form.Label>Set Space Privacy</Form.Label>
						<ToggleButtonGroup
							type='radio'
							name='editPrivacyOptions'
							defaultValue={1}
							onChange={(val) =>
								setShowSpace({ ...showSpace, privacy: val })
							}>
							<ToggleButton
								style={{
									borderTopLeftRadius: '1.8rem',
									borderBottomLeftRadius: '1.8rem',
								}}
								className='p-3'
								variant='outline-dark'
								id='privacy-1'
								value={0}>
								<i className='fa-solid fa-bullhorn me-2'></i>Public
							</ToggleButton>
							<ToggleButton
								className='p-3'
								variant='outline-dark'
								id='privacy-2'
								value={1}>
								<i className='fa-solid fa-lock me-2'></i>Private
							</ToggleButton>
							<ToggleButton
								style={{
									borderTopRightRadius: '1.8rem',
									borderBottomRightRadius: '1.8rem',
								}}
								className='p-3'
								variant='outline-dark'
								id='privacy-3'
								value={2}>
								<i className='fa-solid fa-key me-2'></i>Invite Only
							</ToggleButton>
						</ToggleButtonGroup>
						</Row>
						{showSpace.content<2 &&
							<>
								
								<Row className='align-self-start'>
								<Form.Label>Set Space Layout</Form.Label>
								<ToggleButtonGroup
									type='radio'
									name='editLayoutOptions'
									defaultValue={0}
									onChange={(val) => setShowSpace({ ...showSpace, layout: val })}>
									<ToggleButton
										style={{
											borderTopLeftRadius: '1.8rem',
											borderBottomLeftRadius: '1.8rem',
										}}
										className='p-3'
										variant='outline-dark'
										id='layout-1'
										value={0}>
										<i className='fa-solid fa-square me-2'></i>Default
									</ToggleButton>
									<ToggleButton
										className='p-3'
										variant='outline-dark'
										id='layout-2'
										value={1}>
										<i className='fa-solid fa-bars me-2'></i>List
									</ToggleButton>
									<ToggleButton
										style={{
											borderTopRightRadius: '1.8rem',
											borderBottomRightRadius: '1.8rem',
										}}
										className='p-3'
										variant='outline-dark'
										id='layout-3'
										value={2}>
										<i className='fa-solid fa-grip me-2'></i>Grid
									</ToggleButton>
								</ToggleButtonGroup>
								</Row>
							</>}
						{showSpace.content==3 &&
                        <>
                        <Form.Group controlId="embed_script">
                        <Form.Label>Embed Script</Form.Label>
                        <Form.Control
                          as="textarea"
                          onChange={(event) => {setShowSpace({ ...showSpace, embed_script: event.target.value })}}
                        />
                        </Form.Group>						
                        </>						
                        }						
					{showSpace.content==2 && <><Form.Check type="switch" id="custom-switch" label="Create course with AI" onChange={(e)=>setCreateWithAI(e.target.checked)}/></>}
					</Stack>
				</Modal.Body>
				<Modal.Footer className='border-0 justify-content-center'>
					<Button className='uuki-btn uuki-community-btn' onClick={addSpace}>
						Save Space
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default SpaceSettings;
