import {
  Button,
  Stack,
  Form,
  InputGroup,
  ListGroup,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function MembershipSettings({
  membershipUrl,
  settings,
  setKey,
  setMembership,
}) {
  const [settingsSet, setSettingsSet] = useState(false);
  const [StripeEnabled, setStripeEnabled] = useState(false);  
  const [url, setUrl] = useState("");
  const [showDelete, setShowDelete] = useState({ id: 0, show: false });
  const [country, setCountry] = useState("US");

  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const connectStripe = () => {
     window.location.href= "/"+currentSlug+"/stripe_connect?country="+country
  };
  const currentId = useSelector((state) => state.communityReducer.communityId);
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  useEffect(() => {
    if (Object.entries(settings).length > 0) {
      setSettingsSet((prev) => true);
      setUrl(membershipUrl);
    }
  }, [settings]);
  useEffect(() => {
	console.log("Href", window.location.href, currentId);  
    axios
      .get("/v2/is_stripe_enabled?commId="+currentId)
      .then((res) => {
        setStripeEnabled(res.data.stripeEnabled);
      })
      .catch(function (error) {
        console.log(error);		  
      });    
  }, [currentId]);  

  const generateUrl = () => {
    axios
      .post("/generate_membership_url", { community_id: currentId })
      .then((res) => {
        toast.success("Membership URL Generated");
        setUrl(res.data);
      })
      .catch(function (error) {
        toast.error("Membership URL Not Generated");
      });
  };
  const deleteMembership = () => {
    axios
      .post("/delete_membership", { id: showDelete.id })
      .then((res) => {
        toast.success("Membership Deleted");
        setShowDelete({ id: 0, show: false });
        window.location.reload();
      })
      .catch(function (error) {
        toast.error("Membership Not Deleted");
      });
  };
  const setEdit = (membership) => {
    if (membership != null) {
      let editSubscribers = settings.subscribers.filter(
        (user) => user.membership_id == membership.id
      );
      let editTopics = settings.topics.filter(
        (user) => user.membership_id == membership.id
      );
      let editNfts = settings.nfts.filter(
        (user) => user.membership_id == membership.id
      );
      setMembership({
        membership: membership,
        subscribers: editSubscribers,
        topics: editTopics,
        nfts: editNfts,
      });
    } else {
      setMembership({});
    }

    setKey("membershipEdit");
  };
  return (
    <>
      <Stack gap={4}>
        <Button
          className="uuki-btn uuki-community-btn align-self-start"
          onClick={() => setEdit(null)}
        >
          Create New Membership
        </Button>
        <Form.Group controlId="telegram">
          <Stack direction="horizontal">
            <Form.Label>Share Membership URL</Form.Label>
            <Button
              className="uuki-btn uuki-community-btn ms-auto"
              onClick={generateUrl}
            >
              Generate
            </Button>
          </Stack>
          <InputGroup className="my-3 settings-input-group">
            <Form.Control
              disabled
              value={
                "https://" +
                window.location.host +
                "/c/" +
                currentSlug +
                "/membership/" +
                url
              }
            />
            <Button
              variant="secondary"
              onClick={(event) => {
                navigator.clipboard.writeText(
                  event.currentTarget.previousSibling.value
                );
              }}
            >
              <i className="fa-regular fa-clipboard"></i>
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="stripe">
          <Form.Label>Connect Stripe</Form.Label>
          <Stack gap={3} direction="horizontal">
           {(StripeEnabled == false ) &&
           <Form.Group controlId="country">
              <Form.Select
                defaultValue={country}
                onChange={(event) => setCountry(event.target.value)}
              >
                <option value="US">United States</option>
                <option value="IN">India</option>
                <option value="SG">Singapore</option>
                <option value="NL">Netherlands</option>				
              </Form.Select>
            </Form.Group>}
            <Button
              className="uuki-btn uuki-community-btn"
              onClick={connectStripe}
            >
              {StripeEnabled != false
                ? "Connected"
                : "Connect Stripe"}
            </Button>
          </Stack>
        </Form.Group>
        <ListGroup>
          <Form.Label>Current Memberships</Form.Label>
          <Stack gap={3}>
            {settingsSet &&
              settings.memberships.map((membership) => (
                <ListGroup.Item className="border rounded">
                  <Stack direction="horizontal">
                    <b>{membership.name}</b>{" "}
                    <span className="ms-4">
                      {membership.price} {membership.currency} - {" "}
                      {membership.type == 1 ? "Monthly" : "One-Time"}
                    </span>
                    <Dropdown className="ms-auto">
                      <Dropdown.Toggle className="bg-transparent border-0" style={{color:'var(--fontColor)'}}>
                        <i className="fa-solid fa-ellipsis"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setEdit(membership)}>
                          Edit Membership
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() =>
                            setShowDelete({ id: membership.id, show: true })
                          }
                        >
                          Delete Membership
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Stack>
                </ListGroup.Item>
              ))}
          </Stack>
        </ListGroup>
      </Stack>

      <Modal
        show={showDelete.show}
        onHide={() => setShowDelete({ ...showDelete, show: false })}
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>

        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-delete-btn" onClick={deleteMembership}>
            Delete Membership
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MembershipSettings;
