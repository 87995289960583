import {useState} from 'react'
import axios from 'axios'
import {Form,Button,Row,Col,Stack} from 'react-bootstrap'
import { toast } from "react-toastify";
import {ReactComponent as IncludedIcon} from '../../assets/included.svg'
import {ReactComponent as NotIncludedIcon} from '../../assets/not-included.svg'
function RedeemDashboard({user,setKey}) {
    const [code, setCode] = useState('');
    const redeemCode = (e) => {
        e.preventDefault()
        axios.post('/home/redeem_appsumo',{code: code})
        .then((res) => {
			toast.success("Redeem successful");
            window.location.reload();			
		})
        .catch(({ response }) => {
          console.log(response.data);
          if (
            response != null &&
            response.data != null &&
            response.data != ""
          ) {
            toast.error(response.data);
          } else {
            toast.error("something went wrong");
          }
        });		
        
    }
    return (
        <div className="">
            <h4 className="mb-3">Redeem Code</h4>
            <Form className="d-flex justify-content-between mb-3" onSubmit={redeemCode}>
                <Form.Control placeholder="Input your code" type="text" onChange={e => setCode(e.target.value)}/>
                <Button type="submit" className="uuki-btn uuki-dashboard-btn ms-3" >Redeem</Button>
            </Form>
            {(user.asCodes.length>0) &&
                <>
                <h6>My Codes</h6>
                </>
            }
			{user.asCodes && user.asCodes.map(code =>
				<span className="my-3">{code.code}</span>
            )}
            <h6 className="mt-5">Review UUKI on <a href="https://www.g2.com/products/vadoo-uuki/reviews" target="_blank">G2</a>, <a href="https://www.capterra.com/p/239148/uuki/" target="_blank">Capterra</a> to get 2x upgrade</h6>			
            <Row className="mt-5">
            <Col md={6}>
                <h4 className="mb-3">My Plan</h4>
                <Stack className=" p-4 border rounded" gap={3}>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.members} Members</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.communities} Communitites</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.moderators} Moderators</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.storage} Storage</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.spaces} Spaces</b></Stack>
                    <Stack gap={3} direction="horizontal">{(user.limits.removeBrand === 0) ? <NotIncludedIcon/> : <IncludedIcon/>}<b>Remove Brand</b></Stack>
                    <Stack gap={3} direction="horizontal">{(user.limits.cname === 0) ? <NotIncludedIcon/> : <IncludedIcon/>}<b>C-Name</b></Stack>
                    {(user.codes>=3) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom CSS/JavaScript</b> </Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Advanced Analytics and Moderation</b></Stack>
                        </>
                    }
                    {(user.codes>=5) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>API</b> </Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Webhooks</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Wordpress SSO</b></Stack>
                        </>
                    }
                    {(user.enable_addon == 1) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom SMTP</b> </Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom PWA</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Monetization Fee</b></Stack>
                        </>
                    }
                    <div className="content-border"></div>
                    {((user.plan!=0 || user.userCodes!=0) && user.recur_plan==0) ?
                        <Button className="uuki-big-btn" onClick={() => setKey('pricing')} >Upgrade</Button>
                        :
                        <Button className="uuki-big-btn" onClick={() => setKey('upgrade')} >Upgrade</Button>
                    }
                
                </Stack>
                
            </Col>
           
            {(user.enable_addon == 1) && 
                <Col>
                    <h4 className=" mt-3 mt-md-0 mb-3">Premium Add-on</h4>
                    <Stack className=" p-4 border rounded" gap={3}>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom SMTP</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom PWA</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Monetization Fee of 1%</b></Stack>
                        <div className="content-border"></div>
                        <Button className="uuki-big-btn" href={"https://payments.pabbly.com/subscribe/6219fb0318cd581b431c10bc/premium-addon?email="+user.email+"&first_name="+user.name}  target="_blank">Buy Monthly</Button>
                        <Button className="uuki-big-outline-btn" href={"https://payments.pabbly.com/subscribe/6219fbbf18cd581b431c10e5/premium-addon-yearly?email="+user.email+"&first_name="+user.name}  target="_blank">Buy Yearly</Button>
                    </Stack>
                   
                </Col>
            }
        </Row>

        </div>
    )
}

export default RedeemDashboard
