import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios";
import NavbarLesson from "../components/lesson/NavbarLesson";
import LessonContent from "../components/lesson/LessonContent";
import { Tab, Row, Col, Button, Modal, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { communityDetails } from "../redux/slice/communitySlice";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import "../styles/courses.css";
import Quiz from "../components/lesson/Quiz";

function Lesson() {
  const { communitySlug, topicSlug } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [next, setNext] = useState(null);
  const [key, setKey] = useState("");
  const [completeShow, setCompleteShow] = useState(false);
  const [completed, setCompleted] = useState(0);
  const navigate = useNavigate();
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  const dispatch = useDispatch();
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );

  useEffect(() => {
    axios
      .post("/v2/get_lessons", {
        commSlug: communitySlug,
        topicSlug: topicSlug,
      })
      .then((res) => {
        console.log("Res", res);
        setData(res.data);
        setCompleted(res.data.contentCompleted);
        var newHost = "";
        if (currentHost == "/") {
          newHost = currentHost;
        } else {
          newHost = "/c/" + res.data.communitySlug;
        }
        dispatch(
          communityDetails({
            id: res.data.communityId,
            name: res.data.communityName,
            slug: res.data.communitySlug,
            host: newHost,
          })
        );
        document.title = "Course";
      })
      .catch(function (error) {
        console.log("Course Not Found", error);
      });
    if (searchParams.get("lessonId") != null ) {
      setKey("lesson-"+searchParams.get("lessonId"));
    }else if(searchParams.get("quizId") != null){
      setKey("quiz-"+searchParams.get("quizId"));
    }
  }, []);
  useEffect(() => {
    if (next != null) {
      let getCurrentLesson = data.sections.flatMap((a, i) => {
        const j = a.lessons.findIndex((z) => z.page === next);
        return j > -1 ? [i, j] : [];
        });
      let getCurrentQuiz = data.sections.flatMap((a, i) => {
        const j = a.quizzes.findIndex((z) => z.page === next);
        return j > -1 ? [i, j] : [];
        });
      if(getCurrentLesson.length>0){        
        data.sections[getCurrentLesson[0]].lessons[getCurrentLesson[1]].completed = true
      }else if(getCurrentQuiz.length>0){
        data.sections[getCurrentQuiz[0]].quizzes[getCurrentQuiz[1]].submitted = true
      }
      let nextPage = next+1
      let getNextLesson = data.sections.flatMap((a, i) => {
      const j = a.lessons.findIndex((z) => z.page === nextPage);
      return j > -1 ? [i, j] : [];
      });
      let getNextQuiz = data.sections.flatMap((a, i) => {
        const j = a.quizzes.findIndex((z) => z.page === nextPage);
        return j > -1 ? [i, j] : [];
        });
      if(getNextLesson.length>0){
        setKey("lesson-"+data.sections[getNextLesson[0]].lessons[getNextLesson[1]].id)   
      }else if(getNextQuiz.length>0){
        setKey("quiz-"+data.sections[getNextQuiz[0]].quizzes[getNextQuiz[1]].id)
      }
      
       
    }
    
  }, [next]);
  return (
    <Tab.Container
      id="dashboard-nav"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      {data != null && (
        <Row className="main-row  g-0">
          <Col
            className="mt-5 py-4 ps-0 px-lg-0 overflow-hidden"
            lg={isBigScreen ? { span: 6, offset: 2 } : { span: 9 }}
          >
            <Tab.Content>
              {data.sections.map((section) => 
              <>
                  {section.lessons.map((lesson) => 
                    <Tab.Pane className="h-100" eventKey={"lesson-"+lesson.id}>
                      {section.available==true ?
                      <LessonContent
                        communitySlug={communitySlug}
                        topicSlug={topicSlug}
                        setNext={setNext}
                        lesson={lesson}
                        setCompleted={setCompleted}
                        completed={completed}
                        total={data.contentCount}
                        setCompleteShow={setCompleteShow}
                      /> :
                      <div className="ms-md-3 ms-xxl-5 me-xxl-3">
                        <Stack className="course-block py-5 text-center  uuki-box my-md-4 px-3 px-sm-5" gap={2}>
                          <i className="fa-solid fa-lock fa-3x"></i>
                          <h5>Lesson yet to be released</h5>
                          
                        </Stack>
                    </div>}
                    </Tab.Pane> 
                   
                  )}
                   {section.quizzes.map((quiz) => (
                    <Tab.Pane className="h-100" eventKey={"quiz-"+quiz.id}>
                      {section.available==true ?
                      <Quiz
                        communitySlug={communitySlug}
                        topicSlug={topicSlug}
                        setNext={setNext}
                        quiz={quiz}
                        setCompleted={setCompleted}
                        completed={completed}
                        total={data.contentCount}
                        setCompleteShow={setCompleteShow}
                        memberId={data.memberId}
                      />
                      :
                      <div className="ms-md-3 ms-xxl-5 me-xxl-3">
                        <Stack className="course-block py-5 text-center  uuki-box my-md-4 px-3 px-sm-5" gap={2}>
                          <i className="fa-solid fa-lock fa-3x"></i>
                          <h5>Quiz yet to be released</h5>
                          
                        </Stack>
                    </div>}
                    </Tab.Pane>
                  ))}
                </> 
                
              )}
            </Tab.Content>
          </Col>
          <NavbarLesson
            setKey={setKey}
            community={{
              name: data.communityName,
              slug: data.communitySlug,
              logo: data.communityLogo,
              showLogo: data.show_logo,
            }}
            member={{
              name: data.memberName,
              image: data.memberImage,
              id: data.memberId,
              canChat: data.memberCanChat,
            }}
            sections={data.sections}
            completed={completed}
            total={data.contentCount}
          />
        </Row>
      )}
      <Modal
        show={completeShow}
        onHide={() => setCompleteShow(false)}
        aria-labelledby="edit-section-modal"
        centered
      >
        <Modal.Body style={{height:'10rem'}}>
          <Stack gap={3} className="text-center">
            <i className="fa-solid fa-graduation-cap fa-3x"></i>
            <h5>Congratulations on completing</h5>
            <h5><b>{data!=null && data.courseTitle}</b></h5>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button
            className="mt-4 uuki-btn uuki-community-btn"
            onClick={() => navigate("/c/" + communitySlug + "/" + topicSlug)}
          >
            Back to Overview
          </Button>
        </Modal.Footer>
      </Modal>
    </Tab.Container>
  );
}

export default Lesson;
