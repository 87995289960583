import {
  Form,
  InputGroup,
  Button,
  Stack,
  Image,
  Modal,
  Spinner,
} from "react-bootstrap";
import logoImage from "../../assets/uuki-logo.png";
import placeholderImage from "../../assets/add-image.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DateTimePicker from "react-datetime-picker";

function CommunitySettings({ setCommunitySettings, settings }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showRevert, setShowRevert] = useState(false);
  const [settingsSet, setSettingsSet] = useState(false);
  const [password, setPassword] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [showAffiliate, setShowAffiliate] = useState(false);
  const [showJoinUrl, setShowJoinUrl] = useState(false);  
  const [showDigestDate, setShowDigestDate] = useState(false);  
  const [digestDate, setDigestDate] = useState(null);  
  const [dateInvalid, setDateInvalid] = useState(false);

  const currentId = useSelector((state) => state.communityReducer.communityId);
  const FileDownload = require("js-file-download");
  let commUrl = "";
  if (settings.c_name != "") {
    commUrl = "https://" + settings.c_name;
  } else if (settings.sub_domain != "") {
    commUrl = "https://" + settings.sub_domain + ".uuki.live";
  } else {
    commUrl = "https://app.uuki.live";
  }  
  let joinUrl = settings.join_url;
  useEffect(() => {
    console.log(settings)
    if (Object.entries(settings).length > 0) {
      setSettingsSet((prev) => true);
      setBrandLogo(settings.brand_logo);
      setFavicon(settings.favicon);
      setCoverImage(settings.cover_image);
      setShowAffiliate(settings.show_affiliate);
      setShowJoinUrl(settings.private);	 
      if(settings.weekly_digests==1){
        setShowDigestDate(true)
      } 
      var digest_date = new Date(settings.digest_date)	  
      setDigestDate(digest_date)
    }
  }, [settings]);

  const changeName = useDebouncedCallback((value) => {
    axios
      .post("/change_communityname", { id: currentId, name: value })
      .then((res) => {
        toast.success("Community Name Updated");
        setCommunitySettings({ ...settings, name: value });
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeSubDomain = useDebouncedCallback((value) => {
    axios
      .post("/change_subdomainurl", { id: currentId, url: value })
      .then((res) => {
        toast.success("Community Sub-Domain Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeCname = useDebouncedCallback((value) => {
    axios
      .post("/change_communityurl", { id: currentId, url: value })
      .then((res) => {
        toast.success("Community C-Name Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeMetaName = useDebouncedCallback((value) => {
    axios
      .post("/change_community_metaname", { id: currentId, name: value })
      .then((res) => {
        toast.success("Community Meta-Title Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeMetaDesc = useDebouncedCallback((value) => {
    axios
      .post("/change_community_metadesc", { id: currentId, desc: value })
      .then((res) => {
        toast.success("Community Meta-Description Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changePrivacyPolicy = useDebouncedCallback((value) => {
    axios
      .post("/change_community_privacyLink", { id: currentId, link: value })
      .then((res) => {
        toast.success("Community Privacy Policy Updated");		
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeTos = useDebouncedCallback((value) => {
    axios
      .post("/change_community_tosLink", { id: currentId, link: value })
      .then((res) => {
        toast.success("Community Terms of Service Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeAnalytics = useDebouncedCallback((value) => {
    axios
      .post("/change_community_googleanalyticsid", {
        id: currentId,
        gid: value,
      })
      .then((res) => {
        toast.success("Community Analytics ID Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeHeaderScript = useDebouncedCallback((value) => {
    axios
      .post("/change_community_header", { id: currentId, script: value })
      .then((res) => {
        toast.success("Community Header Script Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeWidgetScript = useDebouncedCallback((value) => {
    axios
      .post("/change_community_widget", { id: currentId, script: value })
      .then((res) => {
        toast.success("Community Widget Script Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  }, 1000);
  const changeLanguage = (value) => {
    axios
      .post("/change_community_language", { id: currentId, language: value })
      .then((res) => {
        toast.success("Community Language Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeThemeColor = (value) => {
    axios
      .post("/change_communitycolor", { id: currentId, color: value })
      .then((res) => {
        toast.success("Community Color Updated");
        setCommunitySettings({ ...settings, color: value });
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeHighlightedColor = (value) => {
    axios
      .post("/change_communitytextcolor", { id: currentId, color: value })
      .then((res) => {
        toast.success("Community Highlight Color Updated");
        setCommunitySettings({ ...settings, text_color: value });
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeTheme = (changeFor, theme) => {
    axios
      .post("/change_theme", {
        communityId: currentId,
        changeFor: changeFor,
        theme: theme,
      })
      .then((res) => {
        toast.success("Community Default Theme Updated");
        setCommunitySettings({ ...settings, theme: theme });
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeHoverColor = (value) => {
    axios
      .post("/change_communityhovercolor", { id: currentId, color: value })
      .then((res) => {
        toast.success("Community Hover Color Updated");
        setCommunitySettings({ ...settings, hover_color: value });
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeFont = (value) => {
    axios
      .post("/change_communityfont", { id: currentId, font: value })
      .then((res) => {
        toast.success("Community Font Updated");
        setCommunitySettings({ ...settings, font: value });
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const generateAppcode = () => {
    axios.post('/generate_app_join_code',{community_id: currentId})
        .then((res) => {
            toast.success('Generated')
            setCommunitySettings({...settings,appCode:res.data})
        }).catch(function (error) {
            toast.error('Not Generated')
        });
  }

  const trainAI = () => {
    axios.post('/v2/train_knowledgebase',{id: currentId})
        .then((res) => {
          const resData = res.data
          if (resData!=null){
           if(resData.result=="success"){
            toast.success('Training Success')
            setCommunitySettings({...settings,ai_embed_code:resData.code})
           }else{
            toast.error(resData.result?resData.result:"Something went wrong")
           }
          }else{
            toast.error("Something went wrong")
          }   
        }).catch(function (error) {
            toast.error('Something went wrong. Please try again')
        });
  }
  const uploadImage = (type, file) => {
    let get_url = "";
    if (type == 0) {
      get_url = "/get_community_brand_logo_url";
    } else if (type == 1) {
      get_url = "/get_community_favicon_url";
    } else if (type == 2) {
      get_url = "/get_community_social_cover_url";
    }
    axios
      .get(get_url, { params: { id: currentId, filename: file.name } })
      .then((res) => {
        res = res.data;
        let url = res.url;
        let fields = res.fields;
        let formData = new FormData();
        for (let key in fields) {
          formData.append(key, fields[key]);
        }
        formData.append("file", file);
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("loadend", uploadComplete, false);
        function uploadComplete(event) {
          let result = "https://communitydata.uuki.live/" + fields["key"];
          if (type == 0) {
            changeBrandLogo(result);
          } else if (type == 1) {
            changeFavicon(result);
          } else if (type == 2) {
            changeCoverImage(result);
          }
        }
        xhr.open("POST", url, true);
        xhr.send(formData);
        toast.success("Image Uploaded");
      })
      .catch(function (error) {
        toast.error("Image Upload Failed");
      });
  };
  const changeBrandLogo = (value) => {
    axios
      .post("/add_community_brand_logo", { id: currentId, url: value })
      .then((res) => {
        setBrandLogo(value);
        toast.success("Community Brand Logo Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeFavicon = (value) => {
    axios
      .post("/add_community_favicon", { id: currentId, url: value })
      .then((res) => {
        setFavicon(value);
        toast.success("Community Favicon Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeCoverImage = (value) => {
    axios
      .post("/add_community_social_cover", { id: currentId, url: value })
      .then((res) => {
        setCoverImage(value);
        toast.success("Community Cover Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeLinksPosition = (value) => {
    axios
      .post("/change_linkslocation", { id: currentId, position: value })
      .then((res) => {
        toast.success("Community Links Position Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const getMemberCsv = () => {
    axios({
      url: "/get_members_csv?id="+currentId,
      method: "GET",
      responseType: "blob", // Important
    })
      .then((response) => {
        FileDownload(response.data, "members.csv");
        toast.success("Community Member List Downloaded");
      })
      .catch(function (error) {
        toast.error("Download Failed");
      });
  };

  const changeAllowAnonymous = (value) => {
    axios
      .post("/change_anonymous", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Anonymous Posting Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };

  const toggleShowCoverImage = (value) => {
    axios
      .post("/v2/update_show_community_cover", { id: currentId, checkbox: value })
      .then((res) => {
        toast.success("Cover image settings updated");
      })
      .catch(function (error) {
        toast.error("Cover image settings update failed");
      });
  };

  const changeMemberInvite = (value) => {
    axios
      .post("/change_member_invite", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Member Invite Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  
  const changeWeeklyDigest = (value) => {
    if (!digestDate) {
      setDateInvalid(true);
    } else {
      setDateInvalid(false);
    }
    if((value==true && digestDate!=null) || value==false){

      axios
        .post("/change_weekly_digest", { id: currentId, value:value, date: digestDate })
        .then((res) => {
          toast.success("Community Weekly Digest Updated");
        })
        .catch(function (error) {
          toast.error("Update Failed");
        });
    }
  };
  const changeAutoApprove = (value) => {
    axios
      .post("/change_auto_approve", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Auto Approve Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeChat = (value) => {
    axios
      .post("/change_enable_chat", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Chat Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changePrivacy = (value) => {
    axios
      .post("/change_allow_public", { id: currentId, value: !value })
      .then((res) => {
        toast.success("Community Privacy Updated");
        setShowJoinUrl(!value);		
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeAffiliateLink = (value) => {
    axios
      .post("/change_affiliate_link", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Affiliate Link Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeShowAffiliate = (value) => {
    setShowAffiliate(value);
    axios
      .post("/change_show_affiliate", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Affiliate Link Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeLoginForm = (value) => {
    axios
      .post("/update_direct_login", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Login Form Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeMemberList = (value) => {
    axios
      .post("/change_member_view", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Member List Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeTrending = (value) => {
    axios
      .post("/change_trending_posts", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Trending Posts Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeTranslate = (value) => {
    axios
      .post("/change_show_translate", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Google Translate Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const changeLogo = (value) => {
    axios
      .post("/change_show_logo", { id: currentId, value: value })
      .then((res) => {
        toast.success("Community Logo Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const deleteComm = () => {
    axios
      .post("/delete_community", { id: currentId, password: password })
      .then((res) => {
        toast.success("Community Deleted");
        setShowDelete(false);
      })
      .catch(function (error) {
        toast.error("Delete Failed");
      });
  };
  const revertUi = () => {
    axios
      .post("/revert_ui", { id: currentId })
      .then((res) => {
        toast.success("Community UI Reverted");
        setShowDelete(false);
      })
      .catch(function (error) {
        toast.error("Revert Failed");
      });
  };

  const changeOpenAIKey = (value)=>{
    axios
      .post("/change_openai_key", { id: currentId, open_ai_key: value })
      .then((res) => {
        toast.success("Open AI key Updated");
        
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  
  }

  const changeCollapseSpace = (value)=>{
    axios
    .post("/change_collapse_space", { id: currentId, change_collapse_space: value })
    .then((res) => {
      toast.success("Collapse Sapces by default updated.");
      
    })
    .catch(function (error) {
      toast.error("Update Failed");
    });
  }

  return (
    <>
      {settingsSet ? (
        <Stack gap={4}>
          <Form.Group controlId="commName">
            <Form.Label>Community Name</Form.Label>
            <Form.Control
              defaultValue={settings.name}
              onChange={(event) => changeName(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="subDomainUrl">
            <Form.Label>Sub-Domain</Form.Label>
            <InputGroup className="settings-input-group">
              <Form.Control
                defaultValue={settings.sub_domain}
                onChange={(event) => changeSubDomain(event.target.value)}
              />
              <InputGroup.Text>.uuki.live</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="commUrl">
            <Form.Label>C-Name</Form.Label>
            <Form.Control
              defaultValue={settings.c_name}
              onChange={(event) => changeCname(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="metaName">
            <Form.Label>Community Meta Title</Form.Label>
            <Form.Control
              defaultValue={settings.meta_title}
              onChange={(event) => changeMetaName(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="metaDesc">
            <Form.Label>Community Meta Description</Form.Label>
            <Form.Control
              defaultValue={settings.meta_desc}
              onChange={(event) => changeMetaDesc(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="privacyLink">
            <Form.Label>Privacy Policy</Form.Label>
            <Form.Control
              defaultValue={settings.privacy_policy}
              onChange={(event) => changePrivacyPolicy(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="tosLink">
            <Form.Label>Terms of Service</Form.Label>
            <Form.Control
              defaultValue={settings.tos}
              onChange={(event) => changeTos(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="analyticsId">
            <Form.Label>Google Analytics ID</Form.Label>
            <Form.Control
              defaultValue={settings.google_analytics}
              onChange={(event) => changeAnalytics(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="sitemap">
            <Form.Label>Sitemap</Form.Label>
            <InputGroup className="mb-3 settings-input-group">
              <Form.Control
                defaultValue={
                  commUrl != "https://app.uuki.live"
                    ? commUrl + "/sitemap.xml"
                    : ""
                }
                disabled
              />
              <Button
                variant="secondary"
                onClick={(event) => {
                  navigator.clipboard.writeText(
                    event.currentTarget.previousSibling.value
                  );
                }}
              >
                <i className="fa-regular fa-clipboard"></i>
              </Button>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="rssFeed">
            <Form.Label>RSS Feed</Form.Label>
            <InputGroup className="mb-3 settings-input-group">
              <Form.Control
                disabled
                defaultValue={
                  commUrl != "https://app.uuki.live" ? commUrl + "/rss.xml" : ""
                }
              />
              <Button
                variant="secondary"
                onClick={(event) => {
                  navigator.clipboard.writeText(
                    event.currentTarget.previousSibling.value
                  );
                }}
              >
                <i className="fa-regular fa-clipboard"></i>
              </Button>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="headerScripts">
            <Form.Label>Custom Header Scripts</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={settings.header_script}
              onChange={(event) => changeHeaderScript(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="sideWidgetContent">
            <Form.Label>Custom Side-Widget Content</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={settings.widget_script}
              onChange={(event) => changeWidgetScript(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="commLanguage">
            <Form.Label>Community Language </Form.Label>
            <Form.Select
              defaultValue={settings.language}
              onChange={(event) => changeLanguage(event.target.value)}
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Spanish">Spanish</option>
              <option value="Simple Chinese">Simple Chinese</option>
              <option value="Traditional Chinese">Traditional Chinese</option>
              <option value="Portuguese">Portuguese</option>
              <option value="Khmer">Khmer</option>
              <option value="Dutch">Dutch</option>
              <option value="Dutch">Hebrew</option>
              <option value="Arabic">Arabic</option>
              <option value="Czech">Czech</option>			  
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="commColor">
            <Form.Label>Community Color</Form.Label>
            <Stack gap={3} direction="horizontal">
              <input defaultValue={settings.color} type="color" />
              <Button
                className="uuki-btn uuki-community-btn"
                onClick={(event) =>
                  changeThemeColor(event.target.previousSibling.value)
                }
              >
                Update
              </Button>
            </Stack>
          </Form.Group>
          <Form.Group controlId="highlightColor">
            <Form.Label>Highlighted Text Color</Form.Label>
            <Stack direction="horizontal" gap={2}>
              <Button
                className="uuki-square-btn"
                style={{ color: "black" }}
                onClick={() => changeHighlightedColor("#000000")}
              >
                Black
              </Button>
              <Button
                className="uuki-square-btn"
                onClick={() => changeHighlightedColor("#ffffff")}
              >
                White
              </Button>
            </Stack>
          </Form.Group>
          <Form.Group controlId="defaultTheme">
            <Form.Label>Member Default Theme</Form.Label>
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="light"
                style={{ border: "1px solid #c6c6c6" }}
                className="uuki-square-btn"
                onClick={() => changeTheme(0, "light")}
              >
                Light
              </Button>
              <Button
                style={{ backgroundColor: "#2d395a", border: 0 }}
                className="uuki-square-btn"
                onClick={() => changeTheme(0, "dark")}
              >
                Dark
              </Button>
            </Stack>
          </Form.Group>
          <Form.Group controlId="hoverColor">
            <Form.Label>Hover Color</Form.Label>
            <Stack gap={3} direction="horizontal">
              <input defaultValue={settings.hover_color} type="color" />
              <Button
                className="uuki-btn uuki-community-btn"
                onClick={(event) =>
                  changeHoverColor(event.target.previousSibling.value)
                }
              >
                Update
              </Button>
            </Stack>
          </Form.Group>
          <Form.Group controlId="fontStyle">
            <Form.Label>Font Style</Form.Label>
            <Form.Select
              defaultValue={settings.font}
              onChange={(event) => changeFont(event.target.value)}
            >
              <option
                style={{ fontFamily: "Arial, sans-serif" }}
                value="Arial, sans-serif"
              >
                Arial
              </option>
              <option
                style={{ fontFamily: "Helvetica, sans-serif" }}
                value="Helvetica, sans-serif"
              >
                Helvetica
              </option>
              <option
                style={{ fontFamily: "Trebuchet MS, sans-serif" }}
                value="Trebuchet MS, sans-serif"
              >
                Trebuchet MS
              </option>
              <option
                style={{ fontFamily: "Times New Roman, serif" }}
                value="Times New Roman, serif"
              >
                Times New Roman
              </option>
              <option
                style={{ fontFamily: "Helvetica, sans-serif" }}
                value="Helvetica, sans-serif"
              >
                Helvetica
              </option>
              <option
                style={{ fontFamily: "Georgia, serif" }}
                value="Georgia, serif"
              >
                Georgia
              </option>
              <option
                style={{ fontFamily: "Garamond, serif" }}
                value="Garamond, serif"
              >
                Garamond
              </option>
              <option
                style={{ fontFamily: "Courier New, monospace" }}
                value="Courier New, monospace"
              >
                Courier New
              </option>
              <option
                style={{ fontFamily: "Brush Script MT, cursive" }}
                value="Brush Script MT, cursive"
              >
                Brush Script MT
              </option>
              <option
                style={{ fontFamily: "Lato, sans-serif" }}
                value="Lato, sans-serif"
              >
                Lato
              </option>
              <option
                style={{ fontFamily: "Montserrat, sans-serif" }}
                value="Montserrat, sans-serif"
              >
                Montserrat
              </option>
              <option
                style={{ fontFamily: "Oswald, sans-serif" }}
                value="Oswald, sans-serif"
              >
                Oswald
              </option>
              <option
                style={{ fontFamily: "Raleway, sans-serif" }}
                value="Raleway, sans-serif"
              >
                Raleway
              </option>
              <option
                style={{ fontFamily: "Roboto, sans-serif" }}
                value="Roboto, sans-serif"
              >
                Roboto
              </option>
              <option
                style={{ fontFamily: "Roboto Condensed, sans-serif" }}
                value="Roboto Condensed, sans-serif"
              >
                Roboto Condensed
              </option>
              <option
                style={{ fontFamily: "Open Sans, sans-serif" }}
                value="Open Sans, sans-serif"
              >
                Default
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="brandLogo">
            <Form.Label>Brand Logo</Form.Label>
            <Stack gap={3} direction="horizontal">
              <Image
                className="uuki-logo"
                src={brandLogo}
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = placeholderImage;
                }}
              />
              <label for="file-upload-brand" class="custom-file-upload">
                <div className="uuki-btn uuki-community-btn text-center">
                  Update
                </div>
              </label>
              <input
                id="file-upload-brand"
                className="d-none"
                type="file"
                onChange={(e) => uploadImage(0, e.target.files[0])}
              />
            </Stack>
          </Form.Group>
          <Form.Group controlId="favicon">
            <Form.Label>
              Favicon <small>(Recommended 32x32)</small>
            </Form.Label>
            <Stack gap={3} direction="horizontal">
              <Image
                className="uuki-logo"
                src={favicon}
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = placeholderImage;
                }}
              />
              <label for="file-upload-favicon" class="custom-file-upload">
                <div className="uuki-btn uuki-community-btn text-center">
                  Update
                </div>
              </label>
              <input
                id="file-upload-favicon"
                className="d-none"
                type="file"
                onChange={(e) => uploadImage(1, e.target.files[0])}
              />
            </Stack>
          </Form.Group>
          <Form.Group controlId="coverImage">
            <div className="d-flex justify-content-between">
            <Form.Label>
              Cover Image <small>(Recommended 840x120)</small>
            </Form.Label>
            <Form.Check 
              type="switch"
              id="cover-image-switch"
              defaultChecked={settings.show_cover == true ? true : false}
               onChange={(event) => toggleShowCoverImage(event.target.checked)}
                />
           

            </div>
            
            <Stack gap={3} direction="horizontal">
              <Image
                className="uuki-logo"
                src={coverImage}
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = placeholderImage;
                }}
              />
              <label for="file-upload-cover" class="custom-file-upload">
                <div className="uuki-btn uuki-community-btn text-center">
                  Update
                </div>
              </label>
              <input
                id="file-upload-cover"
                className="d-none"
                type="file"
                onChange={(e) => uploadImage(2, e.target.files[0])}
              />
            </Stack>
          </Form.Group>
          <Form.Group controlId="memberEmails">
            <Form.Label>Export Member Emails </Form.Label>
            <Stack gap={3} direction="horizontal">
              <Button
                className="uuki-btn uuki-community-btn"
                onClick={getMemberCsv}
              >
                Download CSV
              </Button>
            </Stack>
          </Form.Group>
          <Form.Group controlId="linkPosition">
            <Form.Label>Change Links Position</Form.Label>
            <Form.Select
              defaultValue={settings.links_location}
              onChange={(event) => changeLinksPosition(event.target.value)}
            >
              <option value="0">Bottom Left</option>
              <option value="1">Right</option>
              <option value="2">Top</option>
            </Form.Select>
          </Form.Group>
		  <Form.Group  controlId="appCode">
            <Form.Label>Android App Code</Form.Label>
            <InputGroup className="settings-input-group">
                <Form.Control defaultValue={(settings.appCode != "") ? settings.appCode:""} disabled/>
                <Button variant="secondary" onClick={(event) => {
                  navigator.clipboard.writeText(
                    event.currentTarget.previousSibling.value
                  );
                }}>
                <i className="fa-regular fa-clipboard"></i>
                </Button>
            </InputGroup>
          </Form.Group>
          <Button className="uuki-btn uuki-community-btn align-self-start" onClick={generateAppcode}>Generate</Button>
          <Form.Group controlId="openAIKey">
            <Form.Label>OpenAI Key</Form.Label>
            <Form.Control
              type="password"
              defaultValue={settings.open_ai_key}
              onChange={(event) => changeOpenAIKey(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="trainAI">
            <Form.Label>Train AI and Generate Embed Code</Form.Label>
            <InputGroup className="settings-input-group">
                <Form.Control defaultValue={(settings.ai_embed_code != "") ? settings.ai_embed_code:""} disabled/>
                <Button variant="secondary" onClick={(event) => {
                  navigator.clipboard.writeText(
                    event.currentTarget.previousSibling.value
                  );
                }}>
                <i className="fa-regular fa-clipboard"></i>
                </Button>
            </InputGroup>
          </Form.Group>	
          <Button className="uuki-btn uuki-community-btn align-self-start" onClick={trainAI}>Train AI</Button>
          	   
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Turn Community Private"
            defaultChecked={settings.private == false ? true : false}
            onChange={(event) => changePrivacy(event.target.checked)}
          /> 	
          { showJoinUrl == true ? null :		  
          <Form.Group controlId="joinUrl">
            <Form.Label>Join Url</Form.Label>
            <InputGroup className="mb-3 settings-input-group">
              <Form.Control
                defaultValue={
                  joinUrl != ""
                    ? commUrl + "/joincommunity/"+joinUrl
                    : ""
                }
                disabled
              />
			  
              <Button
                variant="secondary"
                onClick={(event) => {
                  navigator.clipboard.writeText(
                    event.currentTarget.previousSibling.value
                  );
                }}
              >
                <i className="fa-regular fa-clipboard"></i>
              </Button>
            </InputGroup>
          </Form.Group>		  
          }		  
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Show Affiliate Link"
            defaultChecked={settings.show_affiliate == true ? true : false}
            onChange={(event) => changeShowAffiliate(event.target.checked)}
          />
          {showAffiliate == true && (
            <Form.Group controlId="commName">
              <Form.Label>Enter Affiliate Link</Form.Label>
              <Form.Control
                defaultValue={settings.affiliate_link}
                onChange={(event) => changeAffiliateLink(event.target.value)}
              />
            </Form.Group>
          )}

          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Weekly Digests"
            defaultChecked={settings.weekly_digests == true ? true : false}
            // onChange={(event) => changeWeeklyDigest(event.target.checked)}
            onChange={(event) => {setShowDigestDate(event.target.checked);event.target.checked==false&&changeWeeklyDigest(false)}}

          />
          {showDigestDate==true && 
           <div>
            <Form.Label>Enter a date to start weekly from</Form.Label>
             <Stack gap={4} direction="horizontal">
                <div className="d-flex flex-column">
                  <DateTimePicker onChange={(e)=>setDigestDate(e)} value={digestDate} />
                  {dateInvalid &&
                    <span style={{ color: "red" }}>
                      Please enter a valid date and time
                    </span>
                  }
                </div>
                <Button className="uuki-btn uuki-community-btn" onClick={()=>changeWeeklyDigest(true)}>Save</Button>
                       </Stack>
           </div>}

          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Anonymous Posting"
            defaultChecked={settings.anonymous_posting == true ? true : false}
            onChange={(event) => changeAllowAnonymous(event.target.checked)}
          />
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Member Invite"
            defaultChecked={settings.member_invite == true ? true : false}
            onChange={(event) => changeMemberInvite(event.target.checked)}
          />
          
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Auto-Approve Guests"
            defaultChecked={settings.auto_approve == true ? true : false}
            onChange={(event) => changeAutoApprove(event.target.checked)}
          />
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Community Chat"
            defaultChecked={settings.chat == true ? true : false}
            onChange={(event) => changeChat(event.target.checked)}
          />
        
          
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Login/Signup Form"
            defaultChecked={settings.login_form == true ? true : false}
            onChange={(event) => changeLoginForm(event.target.checked)}
          />
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Member List Display"
            defaultChecked={settings.member_list == true ? true : false}
            onChange={(event) => changeMemberList(event.target.checked)}
          />
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Trending Posts Display"
            defaultChecked={settings.show_trending == true ? true : false}
            onChange={(event) => changeTrending(event.target.checked)}
          />
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Google Translate"
            defaultChecked={settings.show_translate == true ? true : false}
            onChange={(event) => changeTranslate(event.target.checked)}
          />
          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
            label="Community Logo Display"
            defaultChecked={settings.show_logo == true ? true : false}
            onChange={(event) => changeLogo(event.target.checked)}
          />

          <Form.Switch
            className="d-flex flex-row-reverse align-items-center justify-content-between ps-0  content-border-bottom"
            label="Collapse Spaces by default"
            defaultChecked={settings.collapse_spaces === 1 ? true : false}
            onChange={(event) => changeCollapseSpace(event.target.checked)}
          />

          <Form.Group controlId="deleteComm">
            <Form.Label>Delete Community </Form.Label>
            <Stack gap={3} direction="horizontal">
              <Button
                className="uuki-delete-btn"
                onClick={() => setShowDelete(true)}
              >
                Delete
              </Button>
            </Stack>
          </Form.Group>
        </Stack>
      ) : (
        <div className="d-flex justify-content-center">
          {/* <Spinner animation="border" /> */}
          <span class="loader-spinner" />
        </div>
      )}
      <Modal show={showDelete} onHide={() => setShowDelete(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={4}>
            <span>
              Deleting a community will remove all posts, comments and member
              data.
            </span>
            <Form.Group controlId="deletePass">
              <Form.Label>Enter Password</Form.Label>
              <Form.Control
                autoFocus
                onChange={(event) => setPassword((prev) => event.target.value)}
              />
            </Form.Group>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-delete-btn" onClick={deleteComm}>
            Delete Community
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRevert} onHide={() => setShowRevert(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={4}>
            <span>
              Reverting will change all community related pages into the older
              UI for all members and remove any new features. You can opt back
              in anytime.
            </span>
          </Stack>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-delete-btn" onClick={revertUi}>
            Revert to Old UI
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommunitySettings;
