import { useSearchParams } from "react-router-dom";
import { useState, useEffect,useRef } from "react";
import axios from "axios";
import Avatar from "react-avatar";
import {
  Image,
  Stack,
  ProgressBar,
  Tab,
  Nav,
  Badge,
  Button,
  Popover,
  ListGroup,
  OverlayTrigger,
  Form,
  InputGroup,
  Modal
} from "react-bootstrap";
import medalImage from "../../assets/medal.svg";
import { ReactComponent as ChatIcon } from "../../assets/navbar-chat.svg";
import { useMoralis, useNFTBalances, useMoralisWeb3Api } from "react-moralis";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import placeholderImage from "../../assets/add-image.png";
import ReactTimeAgo from "react-time-ago";
import PostContent from "./feed/post/PostContent";
import { useInView, InView } from 'react-intersection-observer';
import { toast } from "react-toastify";
import MemberEditorComponent from "./members/MemberEditorComponent";
import { default as edjsParser } from "editorjs-parser";
import InnerHTML from 'dangerously-set-html-content';
import { userRole } from "../../redux/slice/userSlice";

function MemberProfile({ getKey, setKey, selectedMember, setEditMember,enable_chat }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [NFTData, setNFTData] = useState([]);
  const Web3Api = useMoralisWeb3Api();
  const [member, setMember] = useState(null);
  const [memberPosts, setMemberPost] = useState([]);
  const [memberComments, setMemberComments] = useState([]);  
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [postPageCounter, setPostPageCounter] = useState(1);
  const [commentPageCounter, setCommentPageCounter] = useState(1);
  const [showPostProgress, setShowPostProgress] = useState(true);
  const [showCommentProgress, setShowCommentProgress] = useState(true);
  const [customFieldData,setCustomFieldData] = useState([]);  
  const [showUpdateModal,setShowUpdateModal] = useState(false);
  const [content,setContent] = useState("");
  const currentRole = useSelector((state) => state.userReducer.userRole);  
  const [parsedContent,setParsedContent] = useState("");  
  const [followed, setFollowed] = useState(false);

  const currentTheme = useSelector(
    (state) => state.communityReducer.theme
  );  
  const navigate = useNavigate();
  const {
    authenticate,
    isAuthenticated,
    isAuthenticating,
    user,
    account,
    logout,
  } = useMoralis();
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const editorCore = useRef(null);
  var parser = new edjsParser(undefined);

  const getMemberId = ()=>{
    let getMemberId = null;
    if (searchParams.get("memberId") != null) {
      getMemberId = searchParams.get("memberId");
    } else if (selectedMember != null) {
      getMemberId = selectedMember;
    }

    return getMemberId
  }

  useEffect(() => {
    if (getKey == "memberProfile") {
      getMemberInfo(getMemberId())
    }
    fetchNFTs();
  }, [getKey]);

  const getMemberInfo = (memberId)=>{
    axios
        .get("/v2/getmember/info?id=" + memberId +"&page="+1)
        .then(function (response) {
          if(response.data.description == "") {
			  response.data.description = "<div></div>"
		  }
          setMember(response.data);		
          setFollowed(response.data.followed)  
          setCustomFieldData(response.data.custom_field_data)
          setContentValue(response.data.description_json)	  
          
          if (response.data.is_blocked) {
            setIsUserBlocked(true);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const handleOnHide = ()=>{
    setShowUpdateModal(false)
  }

  const getMemberPosts =()=>{
    let getMemberId = null;
    if (searchParams.get("memberId") != null) {
      getMemberId = searchParams.get("memberId");
    } else if (selectedMember != null) {
      getMemberId = selectedMember;
    }
    axios
        .get("/v2/getmember/posts?id=" + getMemberId +"&page="+postPageCounter)
        .then(function (response) {

          if(response.data.posts.length>0){
            if(memberPosts.length>0){
              console.log(memberPosts)
              setMemberPost((prev) => [...prev, ...response.data.posts]);
            }else{
              setMemberPost( response.data.posts);
            }
           
            setPostPageCounter(postPageCounter+1)
          }else{
            setShowPostProgress(false)
          }
          
          
          
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const getMemberComments = ()=>{
    let getMemberId = null;
    if (searchParams.get("memberId") != null) {
      getMemberId = searchParams.get("memberId");
    } else if (selectedMember != null) {
      getMemberId = selectedMember;
    }
    axios
    .get("/v2/getmember/comments?id=" + getMemberId +"&page="+commentPageCounter)
    .then(function (response) {
      if(response.data.comments.length>0){
        if(memberComments.length>0){
        
          setMemberComments((prev)=>[...prev, ...response.data.comments]);
        }else{
          setMemberComments( response.data.comments);
        }
        setCommentPageCounter(commentPageCounter+1)
      }else{
        setShowCommentProgress(false)
      }
     
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const fetchNFTs = async () => {
    if (
      isAuthenticated &&
      user != null &&
      user.get("ethAddress") != null &&
      user.get("ethAddress") != ""
    ) {
      // get polygon NFTs for address
      const options = {
        chain: "polygon",
        address: user.get("ethAddress"),
      };
      const polygonNFTs = await Web3Api.account.getNFTs(options);
      console.log(polygonNFTs);
      const metadata = JSON.parse(polygonNFTs.result[0].metadata);
      console.log(metadata);
      setNFTData(polygonNFTs.result);
    }
  };


  const saveDescriptionText = async ()=>{
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);
    
    var valueArr = []
    var bodyFormData = new FormData();
    bodyFormData.append('description_json',JSON.stringify(savedData.blocks))
    bodyFormData.append('description',markup)
    bodyFormData.append('memberId',getMemberId())

   if(member!=null){
    for(let i=0;i<member.custom_field_data.length;i++){
      const field = member.custom_field_data[i]
      const id = member.custom_field_data[i].id
      const value = document.getElementById(field.id).value
      valueArr.push({id,value})
      
    }
   }
   
   bodyFormData.append('values',JSON.stringify(valueArr))
   
  axios.post('/v2/save_custom_field_info',bodyFormData).then((response)=>{
    getMemberInfo(getMemberId())
    setShowUpdateModal(false)
    toast.success("Profile Updated")
 })
 .catch((err)=>{
  toast.success("Error updating profile")
 })
  }


  const setContentValue = (description)=>{
    let getDate = Math.floor(new Date().getTime() / 1000);
    if (description!=null && description != "") {
      var json_content;
      try {
        json_content = JSON.parse(description);
      } catch (err) {
        json_content = JSON.parse(description.replace(/\'/g, '"'));
      }
      setContent({
        time: getDate,
        blocks: JSON.parse(json_content),
      });
    }
  }

  const popoverUserActions = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={() => handleReportUser()}
          >
            Report User
          </ListGroup.Item>
          {member != null ? (
            <>
              {member.allow_block ? (
                <ListGroup.Item
                  as="button"
                  className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                  onClick={() => handleBlockUser()}
                >
                  {isUserBlocked ? "Unblock User" : "Block User"}
                </ListGroup.Item>
              ) : (
                ""
              )}
            </>
          ) : (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => handleBlockUser()}
            >
              {isUserBlocked ? "Unblock User" : "Block User"}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const handleBlockUser = () => {
    // action =0 unblock user and action=1 block user
    if (member != null) {
      var action = 1;
      var msg = "User Blocked";
      if (isUserBlocked == true) {
        action = 0;
        msg = "User Unblocked";
      }
      axios
        .post("/v2/block_user", {
          blocked_id: member.id,
          action: action,
        })
        .then((response) => {
          toast.success(msg);
          setIsUserBlocked(!isUserBlocked);
          document.body.click();
        })
        .catch((err) => {
          console.error(err);
          document.body.click();
        });
    } else {
      toast.error("Something went wrong, please try again.");
      document.body.click();
    }
  };

  const handleReportUser = () => {
    axios
      .post("/v2/report_user")
      .then((response) => {
        toast.success("User reported to admin");
        document.body.click();
      })
      .catch((err) => {
        console.error(err);
        document.body.click();
      });
  };

  const followMember = () => {
    axios
      .post("/v2/follow_member",{id:member.id})
      .then((response) => {
        // toast.success("Member Follow Setting Changed");
        setFollowed((prev)=>!prev)
      })
      .catch((err) => {
        console.error(err);
       });
  };
  return (
    <div className="d-flex justify-content-center">
      {member != null && (
        <Stack className="center-container" gap={4}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
            <Stack gap={3} className="uuki-box member-box p-4">
              <Stack direction="horizontal" gap={3} className="mb-2">
                {member.image != "" ? (
                  <Image
                    src={member.image}
                    className="member-profile-image"
                    roundedCircle
                    loading="lazy"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = placeholderImage;
                    }}
                  ></Image>
                ) : (
                  <Avatar
                    name={member.name}
                    size="32"
                    textSizeRatio={2.5}
                    round={true}
                  />
                )}
                <div className='d-flex flex-column' style={{width:'75%'}}>
                  <Stack direction="horizontal" gap={3}>
                    <h6 className="m-0" >{member.name}</h6>
                    {(currentUserId != null && currentUserId != member.user_id)&&<Button className="follow-btn" onClick={followMember}>{followed?"Followed":"Follow"}</Button>}
                  </Stack>
                  {member.bio!=''?<span>{member.bio}</span>:""}
                  <Stack direction="horizontal" gap={3}>
                  {member.location!=''?<small className="text-muted">{member.location}</small>:""}
                  <small>{member.followCount} Followers</small>
                  </Stack>
                  
                </div>

                <div className='d-flex'>
                {((currentUserId != null && currentUserId == member.user_id) || currentRole!=0) && (
                <Button
                  className="bg-transparent text-dark border-0 ms-auto mb-2"
                  onClick={() => {
                    setEditMember(member);
                    setKey("editMember");
                  }}
                >
                  <i className="fa-solid fa-user-pen"></i>
                </Button>
                )}				

                {currentUserId != null && currentUserId !== member.user_id && (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    className=" ms-auto mb-5"
                    overlay={popoverUserActions}
                    rootClose
                  >
                    <i
                      style={{ cursor: "pointer" }}
                      className="ms-auto  mt-2 fa-solid fa-ellipsis"
                    ></i>
                  </OverlayTrigger>
                )}</div>
                
              </Stack>
              <Stack direction="horizontal" gap={3}>
                {member.viewer_role == "admin" ? (
                  <div className="leaderboard-rank">Admin</div>
                ) : (
                  member.viewer_role == "moderator" && (
                    <div className="leaderboard-rank">Moderator</div>
                  )
                )}
                {member.tags.map((tag) => (
                  <div className="leaderboard-rank">{tag}</div>
                ))}
              </Stack>
              <Stack gap={3}>
                {member.allow_ranking && <>
                  <Stack direction="horizontal" gap={2}>
                    <Image src={medalImage} loading="lazy" />
                    <h4 style={{ fontSize: "1.125rem" }} className="pt-3 ">
                      {member != null ? member.total_karma : 0} karma
                    </h4>
                    <span className="ms-auto pt-3">
                      Level {member != null ? member.level : 0}
                    </span>
                  </Stack>
                  <ProgressBar
                    style={{ borderRadius: "1rem", height: "0.6rem" }}
                    now={member.rank_percentage}
                    className="ps-0"
                  />
                  <Stack gap={2} direction="horizontal">
                    <div className="leaderboard-rank py-1">
                      {member != null ? member.current_rank : 0}
                    </div>
                    <span className="ms-auto">
                      Next Rank: {member != null ? member.next_rank_name : ""}
                    </span>
                  </Stack>
                </>}
                <div className="d-flex" >
                  {member.links.length > 0 &&
                    member.links.map((link) => (
                      <>
                        {link.linkFor == "fb" ? (
                          <a href={link.link}>
                            <i className="px-1 fa-2x fab fa-facebook-square"></i>
                          </a>
                        ) : link.linkFor == "insta" ? (
                          <a href={link.link}>
                            <i className="px-1 fa-2x fab fab fa-instagram-square"></i>
                          </a>
                        ) : link.linkFor == "twit" ? (
                          <a href={link.link}>
                            <i className="px-1 fa-2x fab fa-twitter-square"></i>
                          </a>
                        ) : link.linkFor == "li" ? (
                          <a href={link.link}>
                            <i className="px-1 fa-2x fab fa-linkedin"></i>
                          </a>
                        ) : (
                          link.linkFor == "web" && (
                            <a href={link.link}>
                              <i className="px-1 fa-2x fas fa-globe"></i>
                            </a>
                          )
                        )}
                      </>
                    ))}
                </div>
                {(currentUserId != null &&
                    currentUserId === member.user_id) ||
                  isUserBlocked ? (
                    ""
                  ) : (
                    <Button className="uuki-btn uuki-community-btn ms-auto" onClick={() =>
                      navigate(
                        "/chat/" +
                          currentSlug +
                          "?newId=" +
                          member.user_id +
                          "&newName=" +
                          member.name +
                          "&newImage=" +
                          member.image
                      )
                    }>
                      {" "}
                      <ChatIcon /> Message
                    </Button>
                  )}
              </Stack>
              <div className="content-border"></div>

              <Stack gap={1} direction="horizontal">
                <Nav variant="pills" className="text-nowrap ">
                  <Stack direction="horizontal" gap={2} >
                    <Nav.Item className="members-link">
                      <Nav.Link
                        className="d-flex align-items-center members-link"
                        eventKey="profile"
                      >
                        Profile
                      </Nav.Link>
                    </Nav.Item>				  
                    <Nav.Item className="members-link">
                      <Nav.Link
                        className="d-flex align-items-center members-link"
                        eventKey="posts"
                      >
                        Posts
                        <Badge className="ms-2 bg-light text-dark">
                          {member.no_of_posts}
                        </Badge>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="members-link">
                      <Nav.Link
                        className="d-flex align-items-center members-link"
                        eventKey="comments"
						style={{ textDecoration: 'none' }}
                      >
                        Comments
                        <Badge className="ms-2 bg-light text-dark">
                          {member.no_of_comments}
                        </Badge>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="members-link">
                      <Nav.Link
                        className="d-flex align-items-center members-link"
                        eventKey="nfts"
                      >
                        NFTs
                      </Nav.Link>
                    </Nav.Item>					
                  </Stack>
                </Nav>
                
              </Stack>
            </Stack>
            <Tab.Content>
              <Tab.Pane eventKey="posts">
                <Stack gap={3} >
                  { memberPosts.length>0?memberPosts.map((post) => (
                    
                    <div className="uuki-box post" >
                      {post.post_cover != "" && (
                        <Image
                          fluid
                          src={post.post_cover}
                          loading="lazy"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = placeholderImage;
                          }}
                        />
                      )}
                      <div className="post-content pt-3">
                        <div className="d-flex px-4">
                        <small className="text-muted">
                          Posted in {post.topic_name}
                        </small>
                        <small className="text-muted ms-1"  >
                          -
                        </small>
                        
                        <ReactTimeAgo
                          date={post.date}
                          locale="en-US"
                          timeStyle="round"
                          className="ms-1"
                          style={{ opacity: "0.8", fontSize: "12px" }}
                        />
                        </div>
                        
                        <PostContent data={post} />
                      </div>
                      
                    </div>
                    
                  )):""}
                   <InView
						as='div'
						onChange={(inView, entry) => {
							inView == true && getMemberPosts();
						}}>
						 {showPostProgress && <div className='p-4 d-flex justify-content-center' >
							<span class='loader-spinner' />
						</div>}
					</InView>
                </Stack>
               
                
              </Tab.Pane>
              
              <Tab.Pane eventKey="comments">
                <Stack gap={3}>
                  { memberComments.length>0 ? memberComments.map((comment) => (
                    <div className="uuki-box post" >
                      <div className="post-content p-4">
                        <small className="text-muted" style={{cursor:'pointer'}} onClick={() =>
                  navigate("/c/" + currentSlug + "/post/" + comment.slug)
                }>
                          {comment.replied_to == null
                            ? " Commented on " + comment.title
                            : "Replied to " + comment.replied_to}
                        </small>
                        <h6
                          dangerouslySetInnerHTML={{ __html: comment.comment }}
                        />
                      </div>
                    </div>
                  )):""}
                  <InView
						as='div'
						onChange={(inView, entry) => {
							inView == true && getMemberComments();
						}}>
              {showCommentProgress && <div className='p-4 d-flex justify-content-center'>
							<span class='loader-spinner' />
						</div>}
						
					</InView>
                </Stack>
              </Tab.Pane>
              <Tab.Pane eventKey="nfts">
                <Stack gap={3}>
                  {NFTData != null && NFTData.length > 0 ? (
                    NFTData.map((nft) => (
                      <>
                        {nft.metadata != null && (
                          <div className="uuki-box post">
                            <Image
                              fluid
                              src={JSON.parse(nft.metadata).image}
                              loading="lazy"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeholderImage;
                              }}
                            />
                            <div className="post-content p-4">
                              <h6>
                                {nft.metadata != null &&
                                  JSON.parse(nft.metadata).name}
                              </h6>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: JSON.parse(nft.metadata).description,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <h5>No NFT Available</h5>
                  )}
                </Stack>
                <div style={{ paddingTop: "500px"}}></div>
              </Tab.Pane>
              <Tab.Pane eventKey="profile">
                <Stack gap={3} className='uuki-box member-box p-3'>
                  <>
                  <div className="d-flex ">
                    <h4>Profile</h4>
                    {currentUserId != null && currentUserId == member.user_id && (
                    <Button className="bg-transparent text-dark border-0 ms-auto mb-2" onClick={()=>setShowUpdateModal(true)}>
                      <i className="fa-solid fa-user-pen"></i>
                    </Button>
                    )}
                  </div>
                  <div>
                    <span style={{fontWeight:'600'}}>Description</span>
				   <div id="profile-description">
                                   {member.description!="" &&
                                   <>
                                     <InnerHTML html={member.description} />
                                   </>
                                   }
                                   </div>
                    {/* <Form.Control defaultValue={member.description!=null?member.description:""} disabled  dangerouslySetInnerHTML={{ __html: parsedContent }}/> */}
                    {/* <MemberEditorComponent  editorCore={editorCore} content={content} isDisabled={true}></MemberEditorComponent> */}
                  </div>
                  {customFieldData != null && customFieldData.length > 0 ? (
                    customFieldData.map((field) => (
                      
                      <>
                      {(field.show_on_profile || currentRole!=0)?<div classname='d-flex column' key={field.id + `-`+field.value}>
                        
                        <Form.Group className="mb-3" controlId={field.id + `-`+field.value}>
                          <Form.Label style={{fontWeight:'600'}}>{field.name}</Form.Label>
                          
                          <InputGroup className="mb-3">
                            {/* <Form.Control defaultValue={field.value} disabled /> */}
                            <span>{field.value}</span>
                            {/* <Button  className=" uuki-community-btn" onClick={(e)=>{saveCustomField(field)}}>
                            Save
                            </Button> */}
                          </InputGroup>
                          {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                          </Form.Text> */}
                        </Form.Group>
                        
                       
                        </div>:""}
                        
                       
                      </>
                      
                    ))
                    
                  ) : (
                    ""
                  )}

                 
                  </>
                  
                </Stack>
                <div style={{ paddingTop: "500px"}}></div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          
          <div style={{ paddingTop: "500px" }}></div>
        </Stack>
      )}
      
      {customFieldData!=null && member!=null?
        <>
            <Modal show={showUpdateModal} onHide={handleOnHide} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
              <span>Description</span>
              {/* <Form.Control defaultValue={member.description!=null?member.description:""} disabled/> */}
              <MemberEditorComponent  editorCore={editorCore} content={content} isDisabled={false} ></MemberEditorComponent>
            </div>
          {member.custom_field_data!=null && member.custom_field_data.length>0?member.custom_field_data.map((field)=>(
            <>
            <div className="mt-3" key={field.id + `-`+field.name}>
           
                <Form.Label>{field.name}</Form.Label>
                <Form.Control defaultValue={field.value} id={field.id}/> 
            
            </div>
            </>
          )):""}
            
        </Modal.Body>
        <Modal.Footer>
        <Button className='mt-5' variant="primary" onClick={(event)=>{saveDescriptionText()}}> Save Changes</Button>
      </Modal.Footer>
        
        </Modal>
        </>:""}
    </div>
    
  );
}

export default MemberProfile;
