import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Stack,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../styles/signin.css";
import { usernameStatus, userLoggedIn, userId } from "../redux/slice/userSlice";
import uukiLogo from "../assets/uuki-logo.png";
import { toast } from "react-toastify";

function Login() {
  const [commLogo, setCommLogo] = useState("");
  const [commName, setCommName] = useState("");
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUsername = useSelector((state) => state.userReducer.username);
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );

  const applyStyles = (theme) => {
    if (theme == "light") {
      document.documentElement.style.setProperty("--primary", "#ffffff");
      document.documentElement.style.setProperty("--secondary", "#eff1f5");
      document.documentElement.style.setProperty("--fontColor", "#2d395a");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #dfdfdf"
      );
    } else {
      document.documentElement.style.setProperty("--primary", "#2B2B2B");
      document.documentElement.style.setProperty("--secondary", "#000000");
      document.documentElement.style.setProperty("--fontColor", "#ffffff");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #030303"
      );
    }
  };

  useEffect(() => {
    let endpoints = ["/v2/get_community_info?url=" + window.location.href];
    axios
      .all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then(
        (data) => (
          setCommName((commName) => (commName = data[0].data.name)),
          (document.title = data[0].data.name + " Login"),
          setCommLogo((commLogo) => (commLogo = data[0].data.logo)),
          setData(data[0].data),
          data[0].data.theme!=undefined && applyStyles(data[0].data.theme)
        )
      );
  }, []);

  const signIn = (e) => {
    e.preventDefault();
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email && !email.match(validRegex)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }

    if (!password) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }

    if (email && email.match(validRegex) && password) {
      axios
        .post("/v2/login", { email: email, password: password })
        .then((res) => {
          // loginUser({id:res.data.id});
          dispatch(usernameStatus(res.data.name));
          dispatch(userLoggedIn(true));
          dispatch(userId(res.data.id));

          navigate("/");
        })
        .catch(({ response }) => {
          console.log(response.data);
          if (
            response != null &&
            response.data != null &&
            response.data != "" &&
            response.status == 400
          ) {
            toast.error(response.data);
          } else {
            toast.error("something went wrong");
          }
        });
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = "/google_login";
  };

  const handleFBLogin = () => {
    window.location.href = "/facebook_login";
  };

  const handleWPLogin = () => {
    window.location.href = "/wordpress_login";
  };

  const handleLinkedinLogin = () => {
    window.location.href = "/linkedin_login";
  };

  const handleJWTLogin = () => {
    if (
      data != null &&
      typeof data.jwtUrl != "undefined" &&
      data.jwtUrl != ""
    ) {
      window.location.href = data.jwtUrl;
    } else {
      toast.error("JWT config not setup");		
    }
  };
  
  return (
    <>
      <div
        className="d-flex justify-content-center signin-bg"
        // style={{ background: "#F8F9FB" }}
      >
        <div className="form-container">
          <div
            className="d-flex flex-column  align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <h4 className="pt-5">Login</h4>
            {typeof commLogo !== "undefined" && commLogo != "" ? (
              <Image
                className="brand-image-mobile py-3 m-4 m-lg-5"
                src={commLogo}
                id="brand_logo"
              />
            ) : (
              <Image
                className="mt-4"
                fluid
                style={{ maxHeight: "7rem", maxWidth: "15rem" }}
                src={uukiLogo}
                id="brand_logo"
              />
            )}
          </div>

          <Form onSubmit={signIn} className="ms-5 me-5 ps-4 pe-4 mt-5">
            <Form.Group className="mb-3">
              <Form.Label>Enter your email</Form.Label>

              <Form.Control
                isInvalid={emailInvalid}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                type="email"
                className="input-login-signup"
                placeholder="Enter email"
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Enter password</Form.Label>
              <Form.Control
                isInvalid={passwordInvalid}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type="password"
                placeholder="Enter password"
              />
              <Form.Control.Feedback type="invalid">
                Password can't be empty.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Link
                className="text-decoration-none"
                to="/forgot_password"
                style={{ color: "#21cd9c" }}
              >
                Forgot Password
              </Link>
            </div>

            <div className="d-flex justify-content-center">
              <Button className="my-4  w-50 uuki-btn uuki-community-btn" type="submit">
                Sign In
              </Button>
            </div>
          </Form>
		  {((data !=null && typeof data.isPublic !== "undefined" && data.isPublic) || currentHost !== "/") &&
          <div className="d-flex justify-content-center">
            <span> New here?</span>
            <Link
              className="text-decoration-none ms-1"
              to="/signup"
              style={{ color: "#21cd9c", fontWeight: "bold" }}
            >
              Sign Up
            </Link>
          </div>
		  }
          {data != null &&
          ((typeof data.googleEnabled !== "undefined" && data.googleEnabled) ||
            (typeof data.fbEnabled !== "undefined" && data.fbEnabled) ||
            (typeof data.wpEnabled !== "undefined" && data.wpEnabled) ||
            (typeof data.liEnabled !== "undefined" && data.liEnabled == 1) ||
            (typeof data.jwtEnabled !== "undefined" && data.jwtEnabled)) ? (
            <div className="d-flex justify-content-center mt-3">
              <hr style={{ width: "30%" }}></hr>
            </div>
          ) : (
            ""
          )}

          {data != null &&
          typeof data.googleEnabled !== "undefined" &&
          data.googleEnabled ? (
            <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
              <Button
                className="my-1 w-50 uuki-btn uuki-community-btn"
                onClick={handleGoogleLogin}
              >
                Login with Google
              </Button>
            </div>
          ) : (
            ""
          )}

          {data != null &&
          typeof data.fbEnabled !== "undefined" &&
          data.fbEnabled ? (
            <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
              <Button className="my-1 w-50 uuki-btn uuki-community-btn" onClick={handleFBLogin}>
                Login with Facebook
              </Button>
            </div>
          ) : (
            ""
          )}

          {data != null &&
          typeof data.wpEnabled !== "undefined" &&
          data.wpEnabled ? (
            <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
              <Button className="my-1 w-50 uuki-btn uuki-community-btn" onClick={handleWPLogin}>
                {data.wpLoginMsg}
              </Button>
            </div>
          ) : (
            ""
          )}

          {data != null &&
          typeof data.liEnabled !== "undefined" &&
          data.liEnabled == 1 ? (
            <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
              <Button
                className="my-1 w-50 uuki-btn uuki-community-btn"
                onClick={handleLinkedinLogin}
              >
                Login with Linkedin
              </Button>
            </div>
          ) : (
            ""
          )}

          {data != null &&
          typeof data.jwtEnabled !== "undefined" &&
          data.jwtEnabled ? (
            <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
              <Button className="my-1 w-50 uuki-btn uuki-community-btn" onClick={handleJWTLogin}>
                {data.jwtLoginMsg}
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Login;
