import { Stack, Form, Button, InputGroup } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

function EmbedSettings({ settings }) {
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const [settingsSet, setSettingsSet] = useState(false);
  const spaceRef = useRef(null);
  let commEmbed =
    '<iframe style="border: 0; box-shadow: none; width: 100%; height: 100vh;" src="https://' +
    window.location.host +
    currentHost +
    '?iframe=true"></iframe>';
  let commWidget =
    '<script src="https://app.uuki.live/static/widget.js"></script><script>init({communityUrl:"https://' +
    window.location.host +
    currentHost +
    '",buttonColor:"#000"})</script>';
  useEffect(() => {
    Object.entries(settings).length > 0 && setSettingsSet((prev) => true);
  }, [settings]);
  const setSpace = (slug) => {
    spaceRef.current.value =
      '<iframe style="border: 0; box-shadow: none; width: 100%; heightWWW00vh;" src="https://' +
      window.location.host +
      "/c/" +
      currentSlug +
      "/" +
      slug +
      '?iframe=true"></iframe>';
  };
  return (
    <>
      <Stack gap={4}>
        <Form.Group controlId="widget">
          <Form.Label>Community Widget</Form.Label>
          <InputGroup className="settings-input-group">
            <Form.Control disabled defaultValue={commWidget} />
            <Button
              variant="secondary"
              onClick={(event) => {
                navigator.clipboard.writeText(
                  event.currentTarget.previousSibling.value
                );
              }}
            >
              <i className="fa-regular fa-clipboard"></i>
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="embed">
          <Form.Label>Community Embed</Form.Label>
          <InputGroup className="settings-input-group">
            <Form.Control disabled defaultValue={commEmbed} />
            <Button
              variant="secondary"
              onClick={(event) => {
                navigator.clipboard.writeText(
                  event.currentTarget.previousSibling.value
                );
              }}
            >
              <i className="fa-regular fa-clipboard"></i>
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="spaceEmbed">
          <Form.Label>Space Embed</Form.Label>
          <Form.Select onChange={(event) => setSpace(event.target.value)}>
            <option>Select a Space to Embed</option>
            {settingsSet &&
              settings.spaces.map((space) => (
                <option value={space.slug}>{space.name}</option>
              ))}
          </Form.Select>
          <InputGroup className="mt-3 settings-input-group">
            <Form.Control disabled ref={spaceRef} />
            <Button
              variant="secondary"
              onClick={(event) => {
                navigator.clipboard.writeText(
                  event.currentTarget.previousSibling.value
                );
              }}
            >
              <i className="fa-regular fa-clipboard"></i>
            </Button>
          </InputGroup>
        </Form.Group>
      </Stack>
    </>
  );
}

export default EmbedSettings;
