import 'intersection-observer'
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import "./styles/main.css";
import Signup from "./pages/Signup";
import AppsumoSignup from "./pages/AppsumoSignup";
import RedeemFreebie from "./pages/RedeemFreebie";
import HomeFeed from "./pages/HomeFeed";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { userLoggedIn, userId, enableBeta, enableAddon } from "./redux/slice/userSlice";
import { languages } from "./redux/slice/languagesSlice";
import Chat from "./pages/Chat";
import ModeratorDashboard from "./pages/ModeratorDashboard";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import CommunityCode from "./pages/CommunityCode";
import messaging from "./firebase";
import { getToken } from "firebase/messaging";
import { communityDetails, deviceDetails, cookieDetails } from "./redux/slice/communitySlice";
import { readString } from "react-papaparse";
import {getCookie, setCookie} from './Utils.js'
import JoinCommunity from "./pages/JoinCommunity";
import Lesson from "./pages/Lesson";
import AITutor from './pages/AITutor';
import Embed from './pages/Embed';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginSet, setIsLoginSet] = useState(false);
  const dispatch = useDispatch();
  dispatch(cookieDetails({cookieUrl:getCookie("app_url")}));
  const communityUrl = useSelector(
    (state) => state.communityReducer.cookieUrl
  );   
  const isUserLoggedIn = useSelector(
    (state) => state.userReducer.isUserLoggedIn
  );
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  /*
  const papaConfig = {
    complete: (results, file) => {
      dispatch(languages(results.data));
    },
    download: true,
    error: (error, file) => {
      console.log("Error while parsing:", error, file);
    },
  };
  */
  //   useEffect(() => {
  //     let endpoints = ['/v2/isLoggedIn']
  //     axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
  //       (data) =>  (
  //         setIsLoggedIn(data[0].data.isLoggedIn),
  //         loginUser({id:data[0].data.id}),
  //         setIsLoginSet(true)
  //       )
  //     )
  // },[])
  const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window

  useEffect(() => {
    axios
      .get("/v2/isLoggedIn")
      .then(function (response) {
        setIsLoggedIn(response.data.isLoggedIn);
        dispatch(userLoggedIn(response.data.isLoggedIn));
        dispatch(enableBeta(response.data.enable_beta));	
        if (response.data.isLoggedIn === false) {
          dispatch(userId(""));
        } else {
          dispatch(userId(response.data.userId));
        }

        setIsLoginSet(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    var url_host = window.location.hostname;
    if (
      url_host !== "app.uuki.live" &&
      url_host !== "dev.uuki.live" &&
      url_host !== "app.vadoo.tv" &&
      url_host !== "localhost"
    ) {
      dispatch(
        communityDetails({
          host: "/",
        })
      );
    }
    //if(window.language_data==undefined){
    //  readString(languagesCSV, papaConfig);
    //}else{
    dispatch(languages(window.language_data));
    //
    //}
  }, []);
  useEffect(() => {
    if (isSupported()) {	  
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
	  try {
        const msg = messaging;
        if(msg != null) {	  
          getToken(msg, {
            vapidKey:
              "BJdgn_8znxo4cA5QmF4JeynwbA3PDVqVOC-n1dTJq_bDAgX8xgqWRkPGutnEY_z-z7OvLWLJmXYcIgk0YqsgYKg",
          })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
          
              dispatch(deviceDetails(currentToken));
            } else {
              // Show permission request UI
            
              if (isSupported()) {
                Notification.requestPermission().then((permission) => {
                  if (permission === "granted") {
                    console.log("Notification permission granted.");
                  } else {
                    console.log("Unable to get permission to notify.");
                  }
                });
              }
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
		}  
      } catch(error) {
        console.log("Notifications not enabled", error);		  
	  }		  
	}  
  });
  return (
    <div className="App">
      {isLoginSet ? (
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={
				isLoggedIn || currentUserId !== "" ? (
                  <Navigate to="/"/>
                ) : (
                  <Login />
                )} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/appsumo_signup" element={<AppsumoSignup />} />
            <Route exact path="/embed" element={<Embed />} />
            <Route exact path="/redeem_uuki_community_guide" element={<RedeemFreebie />} />			
            {/* <Route exact path="/home" element={<HomeFeed />} /> */}
            <Route
              exact
              path="/"
              element={
                currentHost == "/" ? (
                    <HomeFeed />
                ) : isLoggedIn || currentUserId !== "" ? (
                  <Dashboard />
                ) : (
                  <Login />
                )
              }
            />
            <Route exact path="/c/:communitySlug" element={<HomeFeed />} />
            <Route
              exact
              path="/c/:communitySlug/:topicSlug"
              element={<HomeFeed />}
            />
            <Route exact path="/c/:communitySlug/courses" element={<HomeFeed />} />
            <Route
              exact
              path="/settings/:communitySlug"
              element={
                isLoggedIn || currentUserId !== "" ? <Settings /> : <Login />
              }
            />
            <Route
              exact
              path="/c/:communitySlug/members"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/c/:communitySlug/member"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/c/:communitySlug/post/:postSlug"
              element={<HomeFeed />}
            />
            <Route
              exact
              path="/c/:communitySlug/tags/:tag"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/moderate/:communitySlug"
              element={
                isLoggedIn || currentUserId !== "" ? (
                  <ModeratorDashboard />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              exact
              path="/c/:communitySlug/welcomeMember"
              element={<HomeFeed />}
            />
            <Route
              exact
              path="/c/:communitySlug/analytics"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/c/:communitySlug/notifications"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/chat/:communitySlug"
              element={
                isLoggedIn || currentUserId !== "" ? <Chat /> : <Login />
              }
            />
            <Route
              exact
              path="/invite_requests/:communitySlug"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/c/:communitySlug/membership/:membershipUrl"
              element={
                isLoggedIn || currentUserId !== "" ? <HomeFeed /> : <Login />
              }
            />
            <Route
              exact
              path="/course/:communitySlug/:topicSlug"
              element={
                isLoggedIn || currentUserId !== "" ? <Lesson/> : <Login />
              }
            />

            <Route
              exact
              path="/course/:communitySlug/:topicSlug/aitutor"
              element={
                <AITutor/>
              }
            />
            <Route exact path="/forgot_password" element={<ForgotPassword />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
			      <Route exact path="/community_code" element={
                communityUrl !== "" && communityUrl !== undefined ? <Navigate to={communityUrl}/> : <CommunityCode />
            }/>			
            <Route exact path="/joincommunity/:joinUrl" element={<JoinCommunity/>} />
            <Route exact path="/redeem_appsumo" element={<Navigate to={"/?openTab=redeem"}/>} />			
          </Routes>
        </BrowserRouter>
      ) : (
        <div></div>
      )}
      <ToastContainer autoClose={2500} />
    </div>
  );
}

export default App;
